<template>
  <div>
    <div class="Polaris-Page">
      <PolarisBanner title="Manage Products" bannerType="informational" actionLabel="Manage Products"
        :loading="loadingSync" @action="fnLinkBulkActions" hideCloseBtn
        message="Go to the product listing section an activate your products to appear in Ecart Marketplace." />

      <div class="Polaris-Page-Header">
        <div class="Polaris-Page-Header__MainContent">
          <div class="Polaris-Page-Header__TitleActionMenuWrapper">
            <div>
              <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">Active products</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Polaris-Page__Content">
        <div class="Polaris-Card">
          <div class="">
            <div v-if=" !loading.productList ">
              <div class="Polaris-DataTable" v-if="productsList.length > 0">
                <div class="Polaris-DataTable__ScrollContainer">
                  <table class="Polaris-DataTable__Table">
                    <thead>
                      <tr>
                        <th data-polaris-header-cell="true"
                          class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                          scope="col">Product</th>
                        <th data-polaris-header-cell="true"
                          class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                          scope="col">Price</th>
                        <th data-polaris-header-cell="true"
                          class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                          scope="col">Stock</th>
                        <!-- <th data-polaris-header-cell="true"
                                                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                                                    scope="col">Status</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="Polaris-DataTable__TableRow" v-for=" (product, index) in productsList " :key="index">
                        <th class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop " scope="row">
                          <div class="row">
                            <div class="col-2">
                              <div>
                                <span class="Polaris-Thumbnail Polaris-Thumbnail--sizeSmall">
                                  <img v-image="product.imageUrl" alt="Product information"
                                    class="Polaris-Thumbnail__Image">
                                </span>
                              </div>
                            </div>
                            <div class="col-10">
                              <div>
                                <!-- <p>
                                                                    <a :href=' CONST.ECART.PRODUCT + product.slug '
                                                                        target="_blank" v-if=" product.name != null " v-text=" 'View in Ecart' " > 
                                                                    </a>
                                                                </p> -->
                                <p v-text="product.name" class=" text-truncate"></p>
                              </div>
                            </div>
                          </div>
                        </th>
                        <td
                          class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"
                          v-text=" '$' + product.price">
                        </td>
                        <td
                          class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"
                          v-text="product.quantity"> </td>
                        <!-- <td
                                                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">

                                                    <div class="Polaris-Stack__Item">
                                                        <button class="Polaris-Badge"
                                                            @click="fnApiChangeStatusProduct(index)"
                                                            :class=" product.active ? 'Polaris-Badge--statusSuccess' : 'Polaris-Badge--statusAttention'  ">
                                                            <span class="Polaris-Badge__Content "
                                                                v-if="product.active">Enable</span>
                                                            <span class="Polaris-Badge__Content " v-else>Disable</span>
                                                        </button>
                                                    </div>
                                                </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <PolarisMessage title="No products yet" message="You don't have any products. Please sync your store."
                v-else />
            </div>
            <div v-else>
              <table class="Polaris-DataTable__Table">
                <thead>
                  <tr>
                    <th data-polaris-header-cell="true"
                      class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                      scope="col">Product</th>
                    <th data-polaris-header-cell="true"
                      class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                      scope="col">Price</th>
                    <th data-polaris-header-cell="true"
                      class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                      scope="col">Stock</th>
                    <th data-polaris-header-cell="true"
                      class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                      scope="col">Status</th>
                  </tr>
                </thead>
                <TableSkeleton v-show=" loading.productList " />
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '@shopify/polaris/styles.css';
  import TableSkeleton from './components/prtoducts-table-skeleton';
  import PolarisMessage from '@/components/shopify/polaris-message';
  import PolarisBanner from '@/components/shopify/polaris-banner';
  import Example from './components/example';

  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'

  export default {
    components: {
      TableSkeleton,
      PolarisMessage,
      PolarisBanner,
      Example,
    },
    computed: {
      ...mapState('ShopifyProducts', ['productsList', 'loading']),
      ...mapState('ShopifyStore', ['loadingSync', 'storeInformation']),
    },
    watch: {
      loadingSync() {
        if (!this.loadingSync) {
          this.fnApiGetProducts();
        }
      },
    },
    methods: {
      ...mapActions('ShopifyProducts', ['fnApiGetProducts', 'fnApiChangeStatusProduct']),
      ...mapActions('ShopifyStore', ['fnApiSyncStore', 'fnApiGetStore']),
      fnLinkBulkActions() {
        let tempUrl = this.storeInformation.url + '/admin/bulk?resource_name=Product'
        window.open(tempUrl, '_blank');
      }
    },
    mounted() {
      this.fnApiGetStore(this.$route.query.store_id);
      this.fnApiGetProducts(this.$route.query.store_id);
    }
  }
</script>

<style>

</style>