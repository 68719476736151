<template>

  <div>
    <div class="Polaris-Banner Polaris-Banner--hasDismiss Polaris-Banner--withinPage" tabindex="0" role="status"
      aria-live="polite" aria-labelledby="Banner27Heading" aria-describedby="Banner27Content"
      v-show="showBanner && bannerType == 'hasDismiss' ">
      <div class="Polaris-Banner__Dismiss"  v-if="!hideCloseBtn">
        <button type="button" class="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly"
          @click="showBanner = false" aria-label="Dismiss notification">
          <span class="Polar is-Button__Content">
            <span class="Polaris-Button__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path
                    d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                    fill-rule="evenodd"></path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
      <div class="Polaris-Banner__Ribbon">
        <span class="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored Polaris-Icon--hasBackdrop">
          <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
            <path fill="currentColor" d="M2 3h11v4h6l-2 4 2 4H8v-4H3">
            </path>
            <path
              d="M16.105 11.447L17.381 14H9v-2h4a1 1 0 0 0 1-1V8h3.38l-1.274 2.552a.993.993 0 0 0 0 .895zM2.69 4H12v6H4.027L2.692 4zm15.43 7l1.774-3.553A1 1 0 0 0 19 6h-5V3c0-.554-.447-1-1-1H2.248L1.976.782a1 1 0 1 0-1.953.434l4 18a1.006 1.006 0 0 0 1.193.76 1 1 0 0 0 .76-1.194L4.47 12H7v3a1 1 0 0 0 1 1h11c.346 0 .67-.18.85-.476a.993.993 0 0 0 .044-.972l-1.775-3.553z">
            </path>
          </svg>
        </span>
      </div>
      <div class="Polaris-Banner__ContentWrapper">
        <div class="Polaris-Banner__Heading" id="Banner27Heading">
          <p class="Polaris-Heading" v-text="title"> </p>
        </div>
        <div class="Polaris-Banner__Content" id="Banner27Content">
          <slot name="message-secction">
            <p v-text="message"> </p>
          </slot>
        </div>
      </div>
    </div>


    <div class="Polaris-Banner Polaris-Banner--statusSuccess Polaris-Banner--hasDismiss Polaris-Banner--withinPage"
      tabindex="0" role="status" aria-live="polite" aria-labelledby="Banner49Heading" aria-describedby="Banner49Content"
      v-show="showBanner && bannerType == 'statusSuccess' ">
      <div class="Polaris-Banner__Dismiss"  v-if="!hideCloseBtn">
        <button type="button" class="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly"
          aria-label="Dismiss notification" @click="showBanner = false">
          <span class="Polaris-Button__Content"><span class="Polaris-Button__Icon">
              <span class="Polaris-Icon"><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false"
                  aria-hidden="true">
                  <path
                    d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                    fill-rule="evenodd">
                  </path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
      <div class="Polaris-Banner__Ribbon"><span
          class="Polaris-Icon Polaris-Icon--colorGreenDark Polaris-Icon--isColored Polaris-Icon--hasBackdrop"><svg
            viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
            <circle fill="currentColor" cx="10" cy="10" r="9"></circle>
            <path
              d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m2.293-10.707L9 10.586 7.707 9.293a1 1 0 1 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414">
            </path>
          </svg>
        </span>
      </div>
      <div class="Polaris-Banner__ContentWrapper">
        <div class="Polaris-Banner__Heading" id="Banner49Heading">
          <p class="Polaris-Heading" v-text="title"></p>
        </div>
        <div class="Polaris-Banner__Content" id="Banner49Content">
          <slot name="message-secction">
            <p v-text="message"> </p>
          </slot>

          <div class="Polaris-Banner__Actions">
            <div class="Polaris-ButtonGroup">
              <div class="Polaris-ButtonGroup__Item">
                <div class="Polaris-Banner__PrimaryAction">
                  <button type="button" class="Polaris-Button"
                    :class="loading ? 'Polaris-Button--disabled Polaris-Button--loading':'' " :disabled="loading"
                    @click=" $emit('action') " v-if="actionLabel">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Spinner" v-show="loading ">
                        <span class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                            viewBox="0 0 20 20">
                            <path
                              d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                            </path>
                          </svg>
                        </span>
                        <span role="status">
                          <span class="Polaris-VisuallyHidden" v-text="actionLabel"></span>
                        </span>
                      </span>
                      <span class="Polaris-Button__Text" v-text="actionLabel">
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="Polaris-Banner Polaris-Banner--statusInfo Polaris-Banner--hasDismiss Polaris-Banner--withinPage"
      tabindex="0" role="status" aria-live="polite" aria-labelledby="Banner51Heading" aria-describedby="Banner51Content"
      v-show="showBanner && (bannerType == 'informational') ">
      <div class="Polaris-Banner__Dismiss" v-if="!hideCloseBtn">
        <button type="button" @click="showBanner = false"
          class="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly" aria-label="Dismiss notification">
          <span class="Polaris-Button__Content"><span class="Polaris-Button__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path
                    d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                    fill-rule="evenodd"></path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
      <div class="Polaris-Banner__Ribbon"><span
          class="Polaris-Icon Polaris-Icon--colorTealDark Polaris-Icon--isColored Polaris-Icon--hasBackdrop"><svg
            viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
            <circle cx="10" cy="10" r="9" fill="currentColor"></circle>
            <path
              d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m1-5v-3a1 1 0 0 0-1-1H9a1 1 0 1 0 0 2v3a1 1 0 0 0 1 1h1a1 1 0 1 0 0-2m-1-5.9a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2">
            </path>
          </svg></span></div>
      <div class="Polaris-Banner__ContentWrapper">
        <div class="Polaris-Banner__Heading" id="Banner51Heading">
          <p class="Polaris-Heading" v-text="title"></p>
        </div>
        <div class="Polaris-Banner__Content" id="Banner51Content">
          <slot name="message-secction">
            <p v-text="message"> </p>
          </slot>
          <div class="Polaris-Banner__Actions">
            <div class="Polaris-ButtonGroup">
              <div class="Polaris-ButtonGroup__Item">
                <div class="Polaris-Banner__PrimaryAction">
                  <button type="button" class="Polaris-Button"
                    :class="loading ? 'Polaris-Button--disabled Polaris-Button--loading':'' " :disabled="loading"
                    @click=" $emit('action') " v-if="actionLabel">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Spinner" v-show="loading">
                        <span class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                            viewBox="0 0 20 20">
                            <path
                              d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                            </path>
                          </svg>
                        </span>
                        <span role="status">
                          <span class="Polaris-VisuallyHidden" v-text="actionLabel"></span>
                        </span>
                      </span>
                      <span class="Polaris-Button__Text" v-text="actionLabel">
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
      },
      bannerType: {
        type: String,
        required: true,
      },
      actionLabel: {
        type: String,
      },
      hideCloseBtn: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        showBanner: true,
      }
    }
  }
</script>

<style>

</style>